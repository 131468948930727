import React, { useEffect, useState } from "react"
import { useQueryParam } from "gatsby-query-params";
import { ToastContainer } from 'react-toastify';
import jwt from 'jsonwebtoken';
import 'react-toastify/dist/ReactToastify.css';
import "../components/font-awsome"
import { graphql, Link } from "gatsby"
import {
  DEMO_URL,
} from "gatsby-env-variables"

import Layout from "~/components/layout"
import Seo from "~/components/seo"
import RoundCheckBox from "~/components/round-checkbox"
import styled from "styled-components"
import maintenanceIcon from "~/images/icon_repair.png"
import comingsoonIcon from "~/images/icon_soon.png"
import hotIcon from "~/images/icon_fire.png"
import newIcon from "~/images/icon_new.png"
import featureIcon from "~/images/icon_feature.png"
import ButtonCard from '../components/button';

import allgamesIcon from "~/images/icon_allgames.png"
import FilterGameMobile from '../components/filter-game-mobile';
import filterIcon from '~/images/icon_filter_game.svg';
import PlayUrlContext from "~/context/play-url"

const MainContent = styled.div`
  width: 100%;
  max-width: 1280px;
  padding: 20px;

  @media only screen and (max-width: 1248px) {
    padding: 0 20px;
  }

  @media screen and (max-width: 768px) {
    overflow: hidden;
    height: 90vh;
    margin-top: 60px;
  }
`

const InnerContent = styled.div`
  margin-top: 28px;
  border-radius: 18px;
  display: flex;
  justify-content: space-between;

  .btn-showmore {
    display: flex;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .btn-showmore-mobile {
    display: none;

    @media screen and (max-width: 768px) {
      display: block;
    }
  }

  @media screen and (max-width: 768px) {
    display: block;
    height: 70vh;
    overflow: scroll;
  }

  @media screen and (max-width: 375px) {
    height: 50vh;
    overflow: scroll;
  }
`

const CardWrapper = styled.div`
  display: grid;
  gap: 1.5rem;
  margin-bottom: 70px;

  @media screen and (max-width: 480px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`

const CheckBoxList = styled.div`
  font-size: 12px;
`

const CheckBoxHeading = styled.div`
  border-top: 0.5px solid #7e7e7e;
  margin-top: 12px;
`

const ShowMore = styled.button`
  width: 264px;
  height: 39px;
  margin: 0 auto 70px;
  font-size: 12px;
  color: var(--font-primary);
  background: var(--color-primary);
  border-radius: 8px;

  &:hover {
    opacity: 0.8;
    border-radius: 8px;
    border: 2px solid var(--bg-third);
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 375px) {
    width: 100%;
  }
`

const ToolList = styled.div`
  font-size: 12px;
  margin-top: 26px;

  .sort-btn {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .icon-sort {
    display: none;
    background: var(--bg-icon-secondary);
    mask: url(${filterIcon}) no-repeat center / contain;
    -webkit-mask: url(${filterIcon}) no-repeat center / contain;
    width: 40px;
    height: 40px;
    margin-right: 3px;

    @media screen and (max-width: 768px) {
      display: flex;
    }
  }
`

const TagWrapper = styled.div`
  font-size: 10px;
  width: 150px;
  margin: auto;

  div {
    padding: 2px 5px 2px 15px;
    margin: 3px;

    & + div {
      margin-top: 5px;
    }
  }
`

const GameStatusWrapper = styled.div`
  width: 100%;
  max-width: 188px;
  height: 4vw;
  max-height: 53px;
  background: #fff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;

  img {
    width: 20px;
    height: 20px;
    margin-right: 6px;
  }
`

const SelectBox = styled.div`
  color: var(--font-primary);
  cursor: pointer;
  z-index: 15;

  &:after {
    width: 0 !important;
  }

  .select-list {
    bottom: -10px;
    left: 50%;
    transform: translate(-50%, 100%);
    background: var(--bg-primary);
  }
`

const ContentCard = styled.div`
  overflow: hidden;
  color: var(--font-primary);
  width: 100%;
  height: fit-content;

  .bgImage {
    position: relative;
    overflow: hidden;
    border-radius: 15px;
    box-shadow: 0px 3px 6px #00000029;
    display: inline-block;
    justify-content: center;
    align-items: start;
  }

  .detail-card {
    position: absolute;
    white-space: nowrap;
    z-index: 10;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    width: 100%;
    height: 100%;
    padding: 80px 10px;
    transition: 0.5s all;
    transform: translate(0px, 100px);
    top: 100%;
  }

  &:hover .detail-card {
    transform: translate(0px, 0px);
    top: 0;
  }

  .detailTag {
    position: absolute;
    bottom: 0;
    z-index: 10;
    display: block;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background: transparent linear-gradient(180deg, #ECF4FF00 0%, var(--color-forth) 50%, var(--color-third) 100%) 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    .logo {
      width: 100%;
      max-width: 70px;
      margin: auto;
    }

    .tag {
      font-size: 16px;
    }
  }
`

const TapMenu = styled.div`
  display: none;
  width: 100%;
  height: 60px;
  margin-top: 20px;
  color: var(--font-primary);

  .icon {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent linear-gradient(180deg, var(--bg-card-primary) 0%, var(--bg-card-secondary) 53%, var(--bg-card-third) 100%) 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 1px 1px #D5D5D563, 0px 1px 2px #AE8CBE66;
    opacity: 1;
    border: 1px solid transparent;
    transition: 0.5s all;

    img {
      width: 30px;
      height: 30px;
    }

    &.-active {
      border:  1px solid #8100BEB5;
    }
  }

  ul > li {
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const GamePage = ({
  data: {
    allMarkdownRemark: { edges },
    markdownRemark: {
      fields: { langKey },
      frontmatter
    },
    common,
    word
  },
  pageContext,
}) => {
  const { favicon_logo } = common.edges
    .find(item => item.node.file
      .split(".")[1]
      .replace("/", "") === langKey).node.frontmatter
  const _word = word.edges
    .find(item => item.node.file
      .split(".")[1]
      .replace("/", "") === langKey).node
  const { gameData } = React.useContext(PlayUrlContext)
  const [data, setData] = useState([])
  const [renderData, setRenderData] = useState([])
  const [lazyData, setLazyData] = useState([])
  const querySort = useQueryParam("sort");
  const [perPage] = useState(24)
  const [skip, setSkip] = useState(0)
  const [selected, setSelected] = useState({
    Cate: [],
    Prov: [],
    Feat: [],
  })
  const [listCate, setListCate] = useState([]);
  const [listProv, setListProv] = useState([]);
  const [listFeat, setListFeat] = useState([]);
  const [isShowFilter] = useState(true);
  const [currentCate, setCurrentCate] = useState("All Games");
  const [currentFeat, setCurrentFeat] = useState("Featured");
  const [isShowSort, setIsShowSort] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [backLinks, setBackLink] = useState();
  const [isFilterMobile, setIsFilterMobile] = useState(false);
  const { webConfig } = React.useContext(PlayUrlContext);

  const toggleFilterMobile = () => setIsFilterMobile(!isFilterMobile);

  const openGames = (value) => {
    if (!value.status && value.demoUrl) {
      const playStaging = jwt.sign({
        link: value.demoUrl + `?lang=${langKey}`,
        provider: value.partner,
        gameName: value.name,
        mode: !isMobile ? 'desktop' : 'mobile',
        lang: langKey,
        backLink: backLinks,
        gameType: value.type,
        prefix: value.prefix,
      }, 'wrong-secret')
      const Features = 'top=0,left=0,height=768,width=1366,scrollbars=no'
      window.open(`${DEMO_URL}?link=${playStaging}`, Features, 'popup')
    }
  }

  useEffect(() => {
    const checkMobile =
      /Android|iPod|iPad|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    setIsMobile(checkMobile)
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      setData(gameData)
      setRenderData(gameData)
      const start = skip * perPage
      const end = skip * perPage + perPage
      setLazyData(gameData.slice(start, end))
    }
    fetchData();
  }, [gameData])

  const load = () => {
    setSkip(skip + 1)
  }

  useEffect(() => {
    const start = skip * perPage
    const end = skip * perPage + perPage
    const newLazyData = !skip
      ? [...renderData.slice(start, end)]
      : [...lazyData, ...renderData.slice(start, end)]
    setLazyData(newLazyData)
  }, [skip, renderData])

  useEffect(() => {
    const filterCate = () => {
      setSkip(0)
      const newRenderData = data.filter(item => {
        if (currentCate === "All Games") return item
        else return item.type === currentCate
      })
      setRenderData(newRenderData)
    }

    filterCate()
  }, [currentCate])

  useEffect(() => {
    if (querySort) setCurrentFeat(querySort)
    else setCurrentFeat('Featured')
    const filterFeat = async () => {
      let newRenderData = []
      if (currentFeat === "Featured" || 'All Games' === currentFeat) {
        newRenderData = [...data]
      } else if (['slot', 'card', 'arcade', 'fishing', 'board'].includes(currentFeat)) {
        newRenderData = data.filter(v => v.type === currentFeat)
      } else {
        const tagList = data.filter(item => item.tag.map(v => v.toLowerCase()).includes(currentFeat.toLowerCase()))
        const nonTagList = data.filter(item => !item.tag.map(v => v.toLowerCase()).includes(currentFeat.toLowerCase()))
        newRenderData = [...tagList, ...nonTagList]
      }
      setRenderData(newRenderData)
    }
    filterFeat()
  }, [currentFeat, data, querySort])

  useEffect(() => {
    const type = data.map(item => item.type)
    const partner = data.map(item => item.partner)
    const feature = data
      .filter(item => item.tag.length && !item.tag.every(t => t === ""))
      .map(item => item.tag)
    const mapCate = type
      .filter((v, i) => type.indexOf(v) === i)
      .map(item => ({
        text: item,
        icon: item,
      }))
    const partnerList = partner.filter((v, i) => partner.indexOf(v) === i)
      .map(item => item.charAt(0).toUpperCase() + item.slice(1, item.length).toLowerCase())
    const mergedFeature = [].concat.apply([], feature)
    const featureList = mergedFeature.filter(
      (v, i) => mergedFeature.indexOf(v) === i
    ).map(item => item.charAt(0).toUpperCase() + item.slice(1, item.length).toLowerCase())
    setListFeat(featureList)
    setListProv(partnerList)
    setListCate(mapCate)
    setSelected({ Cate: mapCate, Prov: partnerList, Feat: featureList })
  }, [data])

  useEffect(() => {
    let newRenderData = data.filter(
      item =>
        selected["Cate"]
          .map(cate => cate.text.toLowerCase())
          .includes(item.type.toLowerCase()) &&
        selected["Prov"].map(prov => prov.toLowerCase()).includes(item.partner.toLowerCase())
    )
    if (selected.Feat.length > 0 && selected.Feat.length < listFeat.length) {
      const featPrototype = selected.Feat.map(item => {
        return newRenderData.filter(value => {
          return value.tag.includes(item.toUpperCase())
        })
      })
      const mergedFeat = [].concat.apply([], featPrototype)
      newRenderData = mergedFeat.filter((v, i) => mergedFeat.indexOf(v) === i)
    }
    setRenderData(newRenderData)
    setSkip(0)
  }, [selected])

  const handleCheck = e => {
    setCurrentCate("All Games")
    const { className, id, checked } = e.target
    const isHeading = ["Category", "Provider", "Feature"].includes(id)
    const isCate = className === "Cate"
    const isFeat = className === "Feat"
    const list = isCate ? [...listCate] : isFeat ? [...listFeat] : [...listProv]
    const addList = isCate
      ? [
        ...selected[className],
        {
          text: id,
          icon: id,
        },
      ]
      : [...selected[className], id]
    const removeList = isCate
      ? selected[className].filter(item => item.text !== id)
      : selected[className].filter(item => item !== id)
    const newSelected = { ...selected }
    newSelected[className] =
      isHeading && checked
        ? list
        : isHeading && !checked
          ? []
          : checked
            ? addList
            : removeList
    setSelected(newSelected)
  }

  const varFilter = {
    frontmatter,
    isShowFilter,
    CheckBoxList,
    CheckBoxHeading,
    RoundCheckBox,
    handleCheck,
    selected: selected,
    listCate,
    listProv,
    listFeat
  }

  return (
    <Layout pageContext={pageContext} varFilter={varFilter}>
      <ToastContainer />
      <Seo
        // title={frontmatter.metadata.metaTitle}
        // description={frontmatter.metadata.metaDescription}
        title='AMBPLAY'
        description='AMBPLAY'
        favicon_logo={`${webConfig && webConfig.favicon ? webConfig.favicon : favicon_logo}`}
      />
      {isFilterMobile && (
        <FilterGameMobile
          toggleFilterMobile={toggleFilterMobile}
          varFilter={varFilter}
        />
      )}
      <MainContent>
        <ToolList className="flex justify-between relative">
          <div className="headText">
            {_word.frontmatter.game}
          </div>
          <div className="sort-btn">
            <SelectBox
              className="relative flex items-center select-box"
              onClick={() => setIsShowSort(!isShowSort)}
            >
              {_word.frontmatter.sort_by} :
              <div className="font-bold" style={{ marginLeft: "10px" }}>
                {_word.frontmatter[currentFeat.toLowerCase()]}
              </div>
              <span className="block"></span>
              <div
                className={`select-list absolute ${isShowSort ? "block" : "hidden"}`}
              >
                {["Featured", ...listFeat].map((value, index) => (
                  <div
                    key={index}
                    className="flex items-center flex-1 h-full"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <Link to={langKey === 'en' ? `/game/?sort=${value}` : `/${langKey}/game/?sort=${value}`}>
                      {_word.frontmatter[value.toLowerCase()]}
                    </Link>
                  </div>
                ))}
              </div>
            </SelectBox>
          </div>
          <button className='icon-sort' onClick={toggleFilterMobile} onKeyDown={toggleFilterMobile} />
        </ToolList>
        <TapMenu>
          <ul className='flex items-center justify-between w-full'>
            <li className='block'>
              <button onClick={() => setCurrentCate('All Games')}>
                <div className={`m-auto icon ${currentCate === 'All Games' ? '-active' : ''}`}>
                  <img src={allgamesIcon} alt='' />
                </div>
                <div className='text-sm text-center'>All Game</div>
              </button>
            </li>
            {listCate && listCate.map((items, index) => (
              <li className='block' key={index}>
                <button onClick={() => setCurrentCate(items.text)}>
                  <div className={`m-auto icon ${currentCate === items.text ? '-active' : ''}`}>
                    <img src={allgamesIcon} alt='' />
                  </div>
                  <div className='text-sm text-center'>{items.text.charAt(0).toUpperCase() + items.text.slice(1)}</div>
                </button>
              </li>
            ))}
          </ul>
        </TapMenu>
        <InnerContent>
          <div className="flex flex-col">
            <CardWrapper className="grid grid-cols-5 gap-6 md:grid-cols-3 lg:grid-cols-6">
              {lazyData.map((value, index) => (
                <ContentCard
                  key={index}
                >
                  <div className='bottom-0 inline-block h-full bgImage'>
                    <img src={value.image.portraitImage} alt='' />
                    <div className='detailTag'>
                      <img src={value.image.logoImage} className='logo' alt='logo' />
                      <TagWrapper className={`bottom-0 cursor-pointer items-center flex-wrap justify-center ${value.tag[0] ? "flex" : "hidden"}`}>
                      <div className='w-full text-center tag'>{value.name[langKey]}</div>
                        {value.tag.map((item, idx) => (
                          <div
                            key={idx}
                            className="table rounded"
                            style={{
                              backgroundColor:
                                item === "NEW"
                                  ? "#13C750"
                                  : item === "HOT"
                                    ? "#FC4C60"
                                    : "#7639FF",
                              color: "#fff",
                              backgroundImage:
                                item === "NEW"
                                  ? `url(${newIcon})`
                                  : item === "HOT"
                                    ? `url(${hotIcon})`
                                    : `url(${featureIcon})`,
                              backgroundSize: "10px",
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "3px center",
                            }}
                          >
                            {_word.frontmatter[item.toLowerCase()]}
                          </div>
                        ))}
                      </TagWrapper>
                      <GameStatusWrapper
                        style={{
                          display: value.status ? "flex" : "none",
                          color:
                            value.status === 900
                              ? "#FD3C84"
                              : value.status === 901
                                ? "#FD9A00"
                                : "#000",
                        }}
                        className="items-center justify-center font-bold game-status"
                      >
                        <div className="flex items-center justify-center">
                          <div>
                            <img
                              src={
                                value.status === 900
                                  ? maintenanceIcon
                                  : comingsoonIcon
                              }
                              alt=""
                              style={{
                                width: value.status === 901 ? "26px" : "20px",
                              }}
                            />
                          </div>
                          <div>
                            {value.status === 900
                              ? "Maintenance"
                              : value.status === 901
                                ? "Coming Soon!"
                                : ""}
                          </div>
                        </div>
                      </GameStatusWrapper>
                    </div>
                    <div className='detail-card'>
                      <div className='w-full text-center'>{value.name[langKey]}</div>
                      <div
                        className='flex justify-center'
                        style={{
                          marginTop: "30px",
                          width: "100%",
                        }}
                      >
                      <button onClick={() => openGames(value)}>
                        <ButtonCard text={'TRY NOW'} />
                    </button>                      </div>
                    </div>
                  </div>
                </ContentCard>
              ))}
            </CardWrapper>
            <div className='flex justify-center btn-showmore'>
              {skip * perPage + perPage < renderData.length && (
                <ShowMore
                  onClick={load}
                  className=""
                >
                  {_word.frontmatter.show_more}
                </ShowMore>
              )}
            </div>
          </div>
          <div className='flex justify-center btn-showmore-mobile'>
            {skip * perPage + perPage < renderData.length && (
              <ShowMore onClick={load}>
                {_word.frontmatter.show_more}
              </ShowMore>
            )}
          </div>
        </InnerContent>
      </MainContent>
    </Layout>
  )
}

export const query = graphql`
  query GamePageTemplate($langKey: String!) {
    allMarkdownRemark(
      filter: {
        collection: {eq: "banner"}
      }
      sort: {order: ASC, fields: frontmatter___order}
    ) {
      edges {
        node {
          file
          frontmatter {
            image
            dot_thumbnail
            banner_text
            button_text
          }
        }
      }
    }

    markdownRemark(
      frontmatter: {templateKey: {eq: "game"}}
      fields: {langKey: {eq: $langKey}}
    ) {
      id
      frontmatter {
        metadata {
          metaDescription
          metaTitle
        }
        filter_toggle
        filter_show
        show_more
        filters
        sort
        signin
        login
        cancel
        username
        password
        search_label
        search_button
        try_now
        enable_menu
      }
      fields {
        langKey
      }
    }

    iconWeb: allMarkdownRemark(filter: {collection: {eq: "icons"}}) {
      edges {
        node {
          id
          frontmatter {
            question
            sort
            sun
            gift
            play
            game
          }
        }
      }
    }

    common: allMarkdownRemark(filter: {collection: {eq: "common"}}) {
      edges {
        node {
          file
          frontmatter {
            favicon_logo
          }
        }
      }
    }

    word: allMarkdownRemark(filter: {collection: {eq: "word"}}) {
      edges {
        node {
          file
          frontmatter {
            search
            play
            home
            game
            news
            download
            trending_now
            promotion
            new_game
            hot_game
            game_category
            slot
            card
            arcade
            fishing
            board
            recommend_game
            featured
            chat
            event
            game_release
            sort_by
            hot
            hit
            recommend
            new
            show_more
            play_now
            try_now
          }
        }
      }
    }
  }
`

export default GamePage
